import Vue from "vue"
import VueScreenSize from "vue-screen-size"
import axios from "~axios"

Vue.use(VueScreenSize)
Vue.mixin({
    computed: {
        $screenWidth() {
            return this.$vssWidth
        }
    }
})

const sendEventFbq = eventName => {
    console.log("sendEventFbq", eventName)
    window.fbq && window.fbq("trackCustom", eventName)
}

window.fbqTrack = sendEventFbq

const tagsContainers = document.querySelectorAll(".course__content_tags")

document.addEventListener("DOMContentLoaded", () => {
    for (const tagsContainer of tagsContainers) {
        if (tagsContainer) {
            const tags = tagsContainer.querySelectorAll(".course__content_tag")

            if (tags.length > 5) {
                const buttons = document.querySelectorAll(".tags-visibility-button")

                for (const button of buttons) {
                    button.classList.remove("none")

                    button.addEventListener("click", () => {
                        button.classList.toggle("rotated-icon")
                        document.querySelectorAll(".course__content_tags").forEach(tagsContainer => {
                            tagsContainer.classList.toggle("tags-hidden")
                        })
                    })
                }
            }
        }
    }
})

const appEl = document.querySelector("div[data-has-app-vue]")
if (appEl) {
    new Vue({
        el: appEl,
        components: {
            "comments-public": () => import("@components/Comments/CommentsPublic"),
            "course-program-short": () => import("@components/Course/CourseProgramShort"),
            "price-with-converted": () => import("@components/PriceWithConverted"),
            "usual-alert": () => import("@components/Alerts/UsualAlert"),
            "course-access-types": () => import("@components/Course/CourseAccessTypes.vue")
        },
        data() {
            return {
                langsKeys: null
            }
        },
        created() {
            this.getTranslation()
        },
        methods: {
            getTranslation() {
                axios
                    .get(window.cached_routes["translation-groups"], {
                        params: {
                            groups: "course,common,create-course,payments,auth,quiz,expert,marathon",
                            lang: window.app_current_locale
                        },
                        headers: { "X-Language": window.app_current_locale }
                    })
                    .then(response => {
                        let langs = response.data
                        this.langsKeys = langs
                        window.langs = langs
                    })
            }
        }
    })
}
